import React, { useState, useEffect } from 'react';
import Spinner from "../../../../helpers/spinner"
import { REGISTRATION_REQUEST } from '../../../../helpers/constants'
import { countries, registraionFields } from "../../../../helpers/constants/data-array"
import Input from '../../../../helpers/input'
import Button from '../../../../helpers/button'
import axiosFetch from "../../../../helpers/axios"
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginAuth } from "../../../../actions/SetLoginAuthActionCreators"
import { isValidEmail, scrollTo } from "../../../../helpers/constants/functions"
import CookieHelper from "../../../../helpers/cookie-helper";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import AlertBanner from "../../../../helpers/alert-banner";

import './styles.scss'

function RegForm({ t }) {

    const emptyValues = {
        companyName: "",
        nameSurname: "",
        city: "",
        street: "",
        zipcode: "",
        phone: "",
        fax: "",
        mobile: "",
        email: "",
        website: "",
        registerBillingHra: "",
        registerBillingHralaw: "",
        taxNo: "",
        vatNo: "",
        comments: "",
        country: "",
        paymentMethod: ""
    }

    const [registrationData, setRegistrationData] = useState({ ...emptyValues })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const [isRedirect, setIsRedirect] = useState(false)
    const [validation, setValidation] = useState({})
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        const userEmail = CookieHelper("get", "userEmail");
        if (userEmail) {
            setIsRedirect(true)
        }
    }, [])

    useEffect(() => {
        const firstErrorLabel = Object.keys(validation)[0] || null;

        scroll && scrollTo(firstErrorLabel);
        setScroll(false)
    }, [scroll, validation]);

    const setValue = (param, value) => {
        setError()
        const data = {
            ...registrationData,
            [param]: value
        }
        setRegistrationData(data)

        setValidation({
            ...validation,
            [param]: ""
        })
    }

    // const setErrorAndRemoveLoading = (errorValue) => {
    //     setError(errorValue)
    //     setIsLoading(false)
    // }

    
    const registration = () => {
        setScroll(true);
        setIsLoading(true)

        let val = {}
        let errors = 0

        registraionFields.forEach(({ isRequired, isEmailValidation, inputId }) => {
            if (isRequired && (registrationData[inputId] === "" || !registrationData[inputId])) {
                scrollTo(`error-${inputId}`)
                val = {
                    ...val,
                    [inputId]: "Field_is_required"
                }
                errors++
            } else if (isEmailValidation && !isValidEmail(registrationData[inputId])) {
                val = {
                    ...val,
                    [inputId]: "Email_error"
                }
                scrollTo(`error-${inputId}`)
                errors++
            }

        })

        setValidation({ ...val })
        setIsLoading(false)

        errors === 0 && setTimeout(() => {
            userRequestReg()
        }, 500);

    }

    const userRequestReg = () => {
        setIsLoading(true)
        const formData = new FormData()

        formData.append("email", registrationData.email)
        formData.append("companyName", registrationData.companyName)
        formData.append("nameSurname", registrationData.nameSurname)
        formData.append("city", registrationData.city)
        formData.append("street", registrationData.street)
        formData.append("zipcode", registrationData.zipcode)
        formData.append("phone", registrationData.phone)
        formData.append("fax", registrationData.fax)
        formData.append("mobile", registrationData.mobile)
        formData.append("website", registrationData.website)
        formData.append("registerBillingHra", registrationData.registerBillingHra)
        formData.append("registerBillingHralaw", registrationData.registerBillingHralaw)
        formData.append("taxNo", registrationData.taxNo)
        formData.append("vatNo", registrationData.vatNo)
        formData.append("comments", registrationData.comments)
        formData.append("country", registrationData.country)
        formData.append("paymentMethod", registrationData.paymentMethod)

        axiosFetch(REGISTRATION_REQUEST, formData)
            // .then((res) => res.toJS())
            .then((result) => {
                console.log("OK", result)
                setIsRedirect(true)
                setIsLoading(false)
            })
        // promiseResolved(response)


    }

    // const promiseResolved = (response) => {
    //     Promise.resolve(response).then((resArr) => {

    //         if (resArr.error) {
    //             setError(resArr.error)
    //         } else {
    //             const { userInfo: { userName, userEmail, userPicture, userId } } = resArr

    //             CookieHelper("set", "userEmail", userEmail);
    //             CookieHelper("set", "userName", userName);

    //             const loginDataObj = {
    //                 name: userName,
    //                 email: userEmail,
    //                 picture: userPicture,
    //                 id: userId
    //             }

    //             setLoginAuth(loginDataObj)
    //             setIsRedirect(true)
    //         }
    //     })
    //     setIsLoading(false)
    // }

    const renderCountryList = () => {
        const options = countries.map(({ label, value }) => {

            return (
                <option value={value} selected={value === registrationData.country}>{label}</option>
            )
        })
        return options
    }

    const renderRegistrationFields = () => {
        const fields = registraionFields.map((field, index) => {
            const { label, inputId, isEmailValidation } = field
            return (
                <div className="form-group">
                    <label className="info-title" htmlFor="exampleInputPassword1">{t(label)} {isEmailValidation && <span>*</span>}</label>
                    <Input
                        type="text"
                        id={inputId}
                        value={registrationData[inputId]}
                        placeholder={t(label)}
                        className={`form-control unicase-form-control text-input ${validation[inputId] && "input-error"}`}
                        onChange={(e) => setValue(inputId, e.target.value)}
                    />
                    {validation[inputId] && <div className="validation-error" id={`error-${inputId}`}>{t(validation[inputId])}</div>}
                </div>
            )
        })

        return fields
    }

    const renderContent = () => {
        return (
            <div>
                {/* {isRedirect && <Redirect to="/" />} */}
                {isRedirect && <AlertBanner
                    type={"success"}
                    fontAwesomeType={"fa-check"}
                    title={t('Success_alert')}
                    timer={5}
                    alertFirstText={t('Redirect')}
                    alertSecondText={t('Seconds')}
                />}

                <div className="panel--body is--wide">

                    {renderRegistrationFields()}

                    <div className="form-group">
                        <label className="info-title" htmlFor="exampleInputPassword1">{t('Select_country')} </label>
                        <select className="form-control unicase-form-control" onChange={(e) => setValue("country", e.target.value)}>
                            <option value="no_country">{t("Select_country")}</option>
                            {renderCountryList()}
                        </select>
                    </div>

                    <div className="form-group">
                        <label className="info-title" htmlFor="exampleInputPassword1">{t('Paymet_method')} <span>*</span></label>
                        <select className="form-control unicase-form-control" onChange={(e) => setValue("paymentMethod", e.target.value)}>
                            <option value="empty" >{t('Paymet_method')}*</option>
                            <option value="sepa" selected={registrationData.paymentMethod === "sepa"}>SEPA</option>
                            <option value="invoice" selected={registrationData.paymentMethod === "invoice"}>Invoice</option>
                            <option value="paypal" selected={registrationData.paymentMethod === "paypal"}>PayPal</option>
                        </select>
                    </div>
                    <p ><strong>{t('Additional_comments')}</strong></p>
                    <div className="textarea">
                        <textarea placeholder="Comments" className={"form-control unicase-form-control"} onChange={(e) => setValue("comments", e.target.value)}>{registrationData.comments}</textarea>
                    </div>
                </div>

                {error && <div className="error-container">{error}</div>}
                <Button
                    type="button"
                    className="btn-upper btn btn-primary submit-btn"
                    text={t('Registration')}
                    disabled={isLoading}
                    onClick={() => registration()}
                />

            </div>
        )
    }


    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({});

export default withRouter(compose(connect(mapStateToProps, {
    setLoginAuth,
}), withNamespaces())(RegForm));

