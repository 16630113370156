import React, { useState, useEffect } from "react";
import Cart from "./cart";
import { withRouter, Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { setGlobalSearchCategory } from "../../../actions/MenuDataActionCreators";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import classNames from 'classnames'

import "./styles.scss";

function HeaderMiddleBar(props) {
  const {
    notification,
    titleRu,
    titleRo,
    descriptionRu,
    descriptionRo,
    lng,
    t,
    productCategoryId,
    menuData: { menu = [] }
  } = props;

  const { id } = useParams()

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const finalTitle = lng === "en" ? titleRu : titleRo;
  const finalDescription = lng === "en" ? descriptionRu : descriptionRo;



  const renderMenuItems = (menu) => {
    const menuContent = menu.map((menuItem, index) => {

      const { name, label, labelColor, url, nameRo, labelRo } = menuItem
      const finalName = lng === "en" ? name : nameRo
      const finalLabel = lng === "en" ? label : labelRo
      const selectedCategory = id || productCategoryId

      return (
        <li className={`mega-menu ${Number(selectedCategory) === Number(menuItem.id) && "active-item"}`} key={index}>

          <Link to={url} >

            {finalName}

            {finalLabel &&
              <span className="menu-label hot-menu hidden-xs" style={{ backgroundColor: labelColor }}>
                {finalLabel}
              </span>
            }
          </Link>
          {/* {subCategories.length > 0 && renderSubcategoryMenu(subCategories, categoryImage)} */}

        </li>
      )
    })

    return menuContent
  }


  return (
    <div
      className={classNames({
        'main-header': true,
        'animate-dropdown': true,
        'fixed': scrollPosition >= 70
      })}
    >
      <div className="container">
        <div className="row middle-header">
          {/* <div className="col-xs-12 col-sm-12 col-md-3 logo-holder">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="" />
              </Link> */}
          {/* <div className="phone-mob">
                <a href={`tel:${adminPhone}`}>{adminPhone}</a>
              </div> */}
          {/* </div>

          </div> */}
          {/* <SearchField {...{ t }} /> */}
          <ul className="nav navbar-nav desktop-menu">
            {menu.length && renderMenuItems(menu)}
            <li className={` mega-menu`} >
              <Link to={"/advices"}>
                {t('Advices')}
              </Link>
            </li>
          </ul>
          <span className="cart-for-mobile">
            <Cart {...{ t }} />
          </span>
        </div>
      </div>
      {notification > 0 && (
        <div className="global-notification">
          <h3>{finalTitle}</h3>
          <div
            className="notification-description"
            dangerouslySetInnerHTML={{
              __html: finalDescription,
            }}
          />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  searchCategory: state.MenuDataReducer.searchCategory,
  adminPhone: state.GlobalSettingsReducer.adminPhone,
  notification: state.GlobalSettingsReducer.notification,
  titleRu: state.GlobalSettingsReducer.titleRu,
  titleRo: state.GlobalSettingsReducer.titleRo,
  descriptionRu: state.GlobalSettingsReducer.descriptionRu,
  descriptionRo: state.GlobalSettingsReducer.descriptionRo,
  productCategoryId: state.MenuDataReducer.globalCategoryId,
  lng: state.PrefsReducer.lng,
});

export default withRouter(
  compose(
    connect(mapStateToProps, {
      setGlobalSearchCategory,
    }),
    withNamespaces()
  )(HeaderMiddleBar)
);
