import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import Categories from "../widgets/categories";
//import { Link } from "react-router-dom"
import { PAGE_ABOUT_US_GET_CONTENT } from "../../../helpers/constants";
import axiosFetch from "../../../helpers/axios";
import Spinner from "../../../helpers/spinner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "./styles.scss";

function About(props) {
  const { lng } = props
  const [isLoading, setIsLoading] = useState(false);
  const [productsList, setProductsList] = useState();

  useEffect(() => {
    const getProductsList = () => {
      setIsLoading(true);

      const aboutContent = axiosFetch(PAGE_ABOUT_US_GET_CONTENT);
      Promise.resolve(aboutContent).then((arrList) => {
        setProductsList(arrList[0]);
        setIsLoading(false);
      });
    };
   
    getProductsList();
  }, []);

  //

  const renderAboutContent = () => {
    const { titleRu, descriptionRu, image, titleRo, descriptionRo } = productsList;
    const finalTitle = lng === "en" ? titleRu : titleRo
    const finalDescription = lng === "en" ? descriptionRu : descriptionRo
    return (
      <>
        <div className="page-title">{finalTitle}</div>
        <img src={image} alt="ABout Us" className="about-us-image"/>
        <div className="page-description"
                dangerouslySetInnerHTML={{
                  __html: finalDescription
                }}></div>
       
      </>
    );
  };

  const content = isLoading ? (
    <Spinner />
  ) : productsList ? (
    renderAboutContent()
  ) : (
    ""
  );

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-9 homebanner-holder">
              {content}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}


const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(
  connect(mapStateToProps, {})(About)
);

