import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import inst from "../../images/inst.png"
import tiktok from "../../images/tiktok.png"
import { AdressIcon } from "../../helpers/icons/icon-adress"
import { EmailIcon } from "../../helpers/icons/icon-email"
import { PhoneIcon } from "../../helpers/icons/icon-phone"

function Footer(props) {
  const { t, contacts, lng } = props;

  const { adresRo, adresRu, phone, phone2, email } = contacts;

  const finalAdres = lng === "en" ? adresRu : adresRo;

  return (
    <footer id="footer" className="footer color-bg">
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="module-heading">
                <h4 className="module-title">{t("Contacts")}</h4>
              </div>

              <div className="module-body">
                <ul className="toggle-footer">
                  <li className="media">
                    <div className="pull-left">
                      <span className="icon fa-stack fa-lg">
                        <AdressIcon />
                      </span>
                    </div>
                    <div className="media-body">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: finalAdres,
                        }}
                      />
                    </div>
                  </li>

                  <li className="media">
                    <div className="pull-left">
                      <span className="icon fa-stack fa-lg">
                        <PhoneIcon />
                      </span>
                    </div>
                    <div className="media-body">
                      {phone && <a href={`tel:${phone}`}>{phone}</a>}
                      {phone2 && <b>/</b>}
                      {phone2 && <a href={`tel:${phone2}`}>{phone2}</a>}
                    </div>
                  </li>

                  <li className="media">
                    <div className="pull-left">
                      <span className="icon fa-stack fa-lg">
                        <EmailIcon />
                      </span>
                    </div>
                    <div className="media-body">
                      <span>
                        {email && <a href={`mailto:${email}`}>{email}</a>}
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="module-heading">
                <h4 className="module-title">{t("About_our_company")}</h4>
              </div>

              <div className="module-body">
                <ul className="list-unstyled">
                  <li className="first">
                    <Link to={"/about"}>{t("About_us")}</Link>
                  </li>
                  {/* <li>
                    <Link to={"/delivery"}>{t("Delivery_and_pay")}</Link>
                  </li> */}
                  <li>
                    <Link to={"/contacts"}>{t("Contacts")}</Link>
                  </li>
                  <li>
                    <Link to={"/calc"}>Calculator</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="module-heading">
                <h4 className="module-title">{t("We_social")}</h4>
              </div>

              <div className="col-xs-12 col-sm-6 no-padding social">
                <ul className="link social-links">
                  <li className="fb pull-left">
                    <a
                      target="_blank"
                      rel="noopener norefner noreferrer"
                      href="https://www.facebook.com/profile.php?id=61555928770466&mibextid=LQQJ4d"
                      title="Facebook Comfortmat"
                    >
                    </a>
                  </li>
                  <li className="pull-left">
                    <a
                      target="_blank"
                      rel="noopener norefner noreferrer"
                      href="https://www.instagram.com/comfortmat.romania?igsh=NTR5dXRhNnhrM2c2&utm_source=qr"
                      title="Instagramm Comfortmat"

                    >
                      <img src={inst} alt="Comfortmat" className="inst" />
                    </a>
                  </li>

                  <li className="pull-left">
                    <a
                      target="_blank"
                      rel="noopener norefner noreferrer"
                      href="https://www.tiktok.com/@comfortmat.romania"
                      title="Tiktok Comfortmat"

                    >
                      <img src={tiktok} alt="Tiktok Comfortmat" className="tiktok" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
  contacts: state.AddToCartReducer.contacts,
});

export default withRouter(
  compose(connect(mapStateToProps, {}), withNamespaces())(Footer)
);
