import React, { useState, useEffect } from "react";
import Spinner from "../../../helpers/spinner"
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Cart from "./cart"
import classNames from 'classnames'
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss"

function HeaderNavBar(props) {

  const { menuData: { menu }, lng, t } = props

  const [isShow, setIsShow] = useState(true)
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const renderSubcategoriesLinks = (subSubCategoryItems) => {
  //   const content = subSubCategoryItems.map((link, index) => {
  //     const { itemName, itemUrl } = link
  //     return (
  //       <li key={index}>
  //         <Link to={itemUrl}>{itemName}</Link>
  //       </li>
  //     )
  //   })

  //   return content
  // }

  // const rendersubSubCategoryItems = (subCategories) => {
  //   const content = subCategories.map((subCatItem, index) => {
  //     const { subCategoryName, subCategoryUrl, subSubCategoryItems } = subCatItem
  //     return (
  //       <div className="col-xs-12 col-sm-12 col-md-3 col-menu" key={index}>

  //         <Link to={subCategoryUrl}>
  //           <h2 className="title">{subCategoryName}</h2>
  //         </Link>
  //         <ul className="links">
  //           {subSubCategoryItems && renderSubcategoriesLinks(subSubCategoryItems)}
  //         </ul>
  //       </div>
  //     )
  //   })

  //   return content
  // }

  // const renderSubcategoryMenu = (subCategories, categoryImage) => {
  //   return (
  //     <ul className="dropdown-menu container">
  //       <li>
  //         <div className="yamm-content">
  //           <div className="row submenu-custom-style">
  //             {rendersubSubCategoryItems(subCategories)}
  //             <div className="col-xs-12 col-sm-12 col-md-4 col-menu custom-banner">
  //               <img alt="" src={categoryImage} />
  //             </div>
  //           </div>
  //         </div>
  //       </li>
  //     </ul>
  //   )
  // }

  const renderMenuItems = (menu) => {
    const menuContent = menu.map((menuItem, index) => {
      const { name, label, labelColor, url, nameRo, labelRo } = menuItem
      const finalName = lng === "en" ? name : nameRo
      const finalLabel = lng === "en" ? label : labelRo
      return (
        <li className="mega-menu" key={index}>

          <Link to={url} onClick={() => setIsShow(!isShow)}>

            {finalName}

            {finalLabel &&
              <span className="menu-label hot-menu hidden-xs" style={{ backgroundColor: labelColor }}>
                {finalLabel}
              </span>
            }
          </Link>
          {/* {subCategories.length > 0 && renderSubcategoryMenu(subCategories, categoryImage)} */}

        </li>
      )
    })

    return menuContent
  }

  const renderSpinner = () => {
    return (
      <div className="spinner-parent">
        <Spinner />
      </div>
    )
  }

  const allMenu = () => {
    return (
      <div //className="nav-bg-class"
        className={classNames({
          'nav-bg-class': true,
          'show-categories': isShow
        })}
      >
        <div
          className="navbar-collapse"
          id="mc-horizontal-menu-collapse"
        >
          <div className="nav-outer">
            <ul className="nav navbar-nav">
              {/* <li className={` mega-menu`} >
                <Link to={"/"}>
                  {t('Home')}
                </Link>
              </li> */}
              {menu.length && renderMenuItems(menu)}
              <li className={` mega-menu`} >
                <Link to={"/delivery"}>
                  {t('Advices')}
                </Link>
              </li>
              {/* <li className={` mega-menu`} >
                <Link to={"/news"}>
                  {t('News')}
                </Link>
              </li> */}
              <li className={`dropdown mega-menu`} >
                <Link to={"/contacts"}>
                  {t('Contacts')}
                </Link>
              </li>
            </ul>
          </div>
          <div className="cart-container">
            <Cart />
          </div>
        </div>
      </div>
    )
  }

  const content = menu ? allMenu() : renderSpinner()

  return (
    <div
      className={classNames({
        'header-nav': true,
        'animate-dropdown': true,
        'fixed': scrollPosition >= 243
      })}
    >
      <div className="container">
        <div className="yamm navbar navbar-default" role="navigation">
          <div className="navbar-header">
            <button
              data-target="#mc-horizontal-menu-collapse"
              //data-toggle="collapse"
              className="navbar-toggle collapsed"
              onClick={() => setIsShow(!isShow)}
              type="button"
            >
              <span className="sr-only"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            <div className="cart-container-for-mobile">
              <Cart />
            </div>
          </div>
          {content}

        </div>
      </div>
    </div>
  );

}
//export default HeaderNavBar;

const mapStateToProps = (state) => ({
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  // isFetching: state.AddToCartReducer.isFetching,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(HeaderNavBar));