import React, { useState, useEffect, Fragment } from "react"
import Header from "../../header"
import Footer from "../../footer"
// import Categories from "../widgets/categories"
// import Filters from "../widgets/filters"
//import { Link } from "react-router-dom"
import { GET_PRODUCTS_LIST_BY_CAT_ID, GET_CAT_NAME_BY_ID, ITEMS_ON_THE_PAGE, APP_TITLE } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import Spinner from "../../../helpers/spinner"
import BreadCrumbs from "../../../helpers/breadcrumbs"
import PageTitle from "../../../helpers/page-title"
import Pagination from '../../../helpers/pagination'
import OneProduct from '../../../helpers/one-product'
//import HotDeals from "../widgets/hot-deals"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter, useParams } from "react-router-dom";
import { connect } from "react-redux";
import CookieHelper from "../../../helpers/cookie-helper";

import "./styles.scss"

function Сategory(props) {
  const { lng, t } = props
  const { id } = useParams()
  const [minValue] = useState(0)
  const [maxValue] = useState(5000)
  const [isLoading, setIsLoading] = useState(false)
  const [productsList, setProductsList] = useState([])
  const [categoryInfo, setCategoryInfo] = useState()
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)

  const userEmail = CookieHelper("get", "userEmail");

  useEffect(() => {

    const getProductsList = (page = 0) => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("сategoryId", id)
      formData.append("currentPage", page)
      formData.append("itemsOnPage", ITEMS_ON_THE_PAGE)
      formData.append("minValue", minValue)
      formData.append("maxValue", maxValue)
      formData.append("userEmail", userEmail)

      const productsResult = axiosFetch(GET_PRODUCTS_LIST_BY_CAT_ID, formData)
      promiseResolved(productsResult, "productsResult")
    }

    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 500);


    const getСategoryInfo = () => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("сategoryId", id)

      const categoryResult = axiosFetch(GET_CAT_NAME_BY_ID, formData)
      promiseResolved(categoryResult, "categoryResult")
     
    }


    getProductsList(currentPage)
    getСategoryInfo()
    

  }, [id, minValue, maxValue, currentPage, userEmail])


  const promiseResolved = (productsResult, state) => {

    setTimeout(() => {
      setIsLoading(false)
    }, 1000);
    
    Promise.resolve(productsResult).then((arrList) => {

      switch (state) {
        case "productsResult":
          if (arrList.length) {
            setTotalCount(arrList[0].total)
          }
          setProductsList(arrList)
          break
        case "categoryResult":
          setCategoryInfo(arrList)
          break

        default:
          break
      }

    
    })
  }

  // const range = {
  //   setMin,
  //   setMax,
  // }

  const paginationClick = page => {
    setIsLoading(true)
    setCurrentPage(page)
  
  }

  const renderFilterTopBar = () => {
    const { categoryName, categoryNameRo } = categoryInfo[0]
    const finalName = lng === "en" ? categoryName : categoryNameRo
    document.title = `${APP_TITLE}${finalName}`
    return (
      <div className="clearfix">
        <div className="row">
          <div className="col col-sm-6 col-md-12">
            <PageTitle text={finalName} />
          </div>
        </div>
      </div>
    )
  }

  const renderProductsItems = (products) => {
    console.log("products", products)
    const content = products.map((oneProduct, index) => {
      return <OneProduct oneProduct={oneProduct} showAddToCart={true} key={index} className={"col-sm-6 col-md-3"} />
    })
    return content
  }

  const renderEmptyContainer = () => {
    return (
      <div className="col-sm-6 col-md-12 wow fadeInUp animated no-content" >
        {t('Empty_category')}
      </div>)
  }

  const renderBreadcrumbs = () => {
    const { categoryId, categoryName, categoryNameRo } = categoryInfo[0]
    const finalName = lng === "en" ? categoryName : categoryNameRo
    return (
      <Fragment>
        <BreadCrumbs
          step1={finalName}
          step1Url={`category/${categoryId}`}
          active={1}
        />
      </Fragment>
    )
  }

  const showPagination = () => {
    return (
      <Pagination
        onClick={paginationClick}
        total={totalCount}
        pageIndex={currentPage}
        pageSize={ITEMS_ON_THE_PAGE}
      />
    )
  }

  // const setCheckFilters = (objMaterials, objWorkZones) => {
  //   setIsLoading(true)
  //   setTimeout(() => {
  //     getProductsList(currentPage, objMaterials, objWorkZones)
  //   }, 500);
  // }
  const content = isLoading ? <Spinner /> : productsList && productsList.length > 0 ? renderProductsItems(productsList) : renderEmptyContainer()

  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            {/* <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories currentCategory={id} />
              <Filters  {...{ setCheckFilters }} />
              <HotDeals />
            </div> */}
            <div className="col-xs-12 col-sm-12 col-md-12 homebanner-holder" id="categoryPage">
              {categoryInfo && renderFilterTopBar()}
              <div className="tab-pane active " id="grid-container">

                <div className="category-product">
                  {categoryInfo && renderBreadcrumbs()}
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                    {content}
                  </div>
                  <div className="row">
                    {totalCount > ITEMS_ON_THE_PAGE && showPagination()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(Сategory));
