import React, { useState, useEffect } from "react";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { GET_ONE_NEW_INFO_BY_ID } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import { useParams } from "react-router-dom"
import Categories from "../widgets/categories"
import Header from "../../header";
import Footer from "../../footer"

import "./styles.scss"
function NewsItemDetails(props) {
  const { lng } = props

  const { id } = useParams()
  const [oneItemDetails, setOneItemDetails] = useState({})

  useEffect(() => {
    const formData = new FormData()
    formData.append("id", id)

    const newsResult = axiosFetch(GET_ONE_NEW_INFO_BY_ID, formData)
    Promise.resolve(newsResult).then((arrList) => {

      setOneItemDetails(arrList[0])
    })
  }, [id])


  const { date, descriptionRU, descriptionRO, image, titleRO, titleRU } = oneItemDetails

  const finalTitle = lng === "en" ? titleRU : titleRO
  const finalDescription = lng === "en" ? descriptionRU : descriptionRO
  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs" id="top-banner-and-menu">
        <div className="container blog-page">

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-9 single-product">
              <div className="detail-block">
                <div className="row  wow fadeInUp">
                  <div className="blog-post wow fadeInUp">
                    <img className="img-responsive" src={image} alt="" />
                    <h1>{finalTitle}</h1>
                    <span class="date-time">{date}</span>
                    <div
                      className="news-description"
                      dangerouslySetInnerHTML={{
                        __html: finalDescription
                      }}></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>

  );

}

const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
});

export default withRouter(compose(connect(mapStateToProps, {}), withNamespaces())(NewsItemDetails));
