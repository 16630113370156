import React, { useEffect, useState } from 'react';
import { SET_ORDER, SET_ORDER_ITEMS, DELETE_CART_ITEMS, SEND_NOTIFICATION_TO_ADMIN, GET_USER_INFO } from "../../../../../helpers/constants"
import { isValidEmail } from "../../../../../helpers/constants/functions"
import axiosFetch from "../../../../../helpers/axios"
import CookieHelper from "../../../../../helpers/cookie-helper";
import { getCartData, getCartInfoFirstLoad } from "../../../../../actions/AddToCartActionCreator"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Spinner from "../../../../../helpers/spinner";

import './styles.scss'

function DeliveryForm(props) {
    const { cartItems, totalSumm, deliveryPriceFinal, totalSummFinal, deliveryType, redirect, t, loginData } = props

    const userEmail = CookieHelper("get", "userEmail");


    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState(userEmail || "")
    const [note, setNote] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [city, setCity] = useState("")
    const [judet, setJudet] = useState("")
    const [facturaType, setFacturaType] = useState('firma')


    const [companyName, setCompanyName] = useState("")
    const [codeFiscal, setCodeFiscal] = useState("")
    const [tradeRegistry, setTradeRegistry] = useState("")
    const [companyPhone, setCompanyPhone] = useState("")
    const [companyFax, setCompanyFax] = useState("")
    // const [companyAdress, setCompanyAdress] = useState("")
    // const [companyCity, setCompanyCity] = useState("")
    // const [companyJudet, setCompanyJudet] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("")
    const [deliveryMethod, setDeliveryMethod] = useState("curier-rapid")
    // const [adress, setAdress] = useState("")
    const [userData, setUserData] = useState({})

    // const [count, setCount] = useState(1)
    const [error, setError] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabledButton] = useState(false)



    useEffect(() => {
        const fetchUserData = () => {
            const { email } = loginData
            const formData = new FormData()
            formData.append("email", email)

            axiosFetch(GET_USER_INFO, formData).then((result) => {
                setUserData(result[0] || {})
                setName(result[0].nameSurname)
                setPhone(result[0].phone)
                setJudet(result[0].judets)
                setCity(result[0].city)
                setCodePostal(result[0].zipcode)

                setCompanyName(result[0].companyName)
                setCodeFiscal(result[0].registerBillingHra)
                setTradeRegistry(result[0].registerBillingHralaw)
                setCompanyPhone(result[0].mobile)
                setCompanyFax(result[0].fax)
                setPaymentMethod(result[0].paymentMethod)
                console.log("result[0]", result[0].paymentMethod)
            })

        }



        userEmail.length > 0 && fetchUserData()
    }, [userEmail, loginData])

    // const changeCount = (action = "inc") => {
    //     if (action === "inc") {
    //         setCount(count + 1)
    //     } else if (count > 1) {
    //         setCount(count - 1)
    //     }
    // }

    const setErrorAndRemoveLoading = errorValue => {
        setError(errorValue)
        setIsLoading(false)
    }

    const setOrder = () => {
        const { id = 0 } = userData
        setIsLoading(true)

        const emailRegex = isValidEmail(email)
        if (email.length > 0 && !emailRegex) {
            setErrorAndRemoveLoading(t('Email_error'))
            setIsLoading(false)
            return false
        }
        setIsDisabledButton(true)

        const token = CookieHelper("get", "token");
        const formData = new FormData();
        formData.append("userId", id)
        formData.append("name", name)
        formData.append("phone", phone)
        formData.append("email", email)
        formData.append("note", note)
        formData.append("adress", "")
        formData.append("token", token)
        formData.append("deliveryType", deliveryType)
        formData.append("totalSumm", totalSumm)
        formData.append("deliveryPriceFinal", deliveryPriceFinal)
        formData.append("totalSummFinal", totalSummFinal)

        formData.append("companyName", companyName)
        formData.append("codeFiscal", codeFiscal)
        formData.append("tradeRegistry", tradeRegistry)
        formData.append("companyPhone", companyPhone)
        formData.append("companyFax", companyFax)
        formData.append("companyAdress", "")
        formData.append("companyCity", "")
        formData.append("companyJudet", "")
        formData.append("facturaType", facturaType)
        formData.append("paymentMethod", paymentMethod)
        formData.append("deliveryMethod", deliveryMethod)

        formData.append("codePostal", codePostal)
        formData.append("city", city)
        formData.append("judet", judet)





        let totalCartItems = 0


        axiosFetch(SET_ORDER, formData).then(orderId => {
            cartItems.forEach(cartLine => {
                totalCartItems++
                const { id, price, count } = cartLine;

                const fd = new FormData();

                fd.append("orderId", orderId)
                fd.append("productId", id)
                fd.append("productPrice", price)
                fd.append("productCount", count)

                axiosFetch(SET_ORDER_ITEMS, fd).then(() => {
                    if (totalCartItems === cartItems.length) {

                        const delFormData = new FormData();

                        delFormData.append("token", token)

                        axiosFetch(DELETE_CART_ITEMS, delFormData).then(() => {
                            setIsLoading(false)
                            redirect()
                            setIsDisabledButton(false)
                        })

                        const sendData = new FormData();

                        sendData.append("orderId", orderId)
                        sendData.append("email", email)
                        axiosFetch(SEND_NOTIFICATION_TO_ADMIN, sendData)

                    }
                })
            })
        })

    }

    const setNameCheck = (value) => {
        // const regex = /^[a-zA-Zа-яА-Я]{0,20}$/
        // if (regex.test(value)) {
        setName(value)
        // }
    }

    const setPhoneCheck = (value) => {
        const regex = /^[0-9]+$/
        if ((regex.test(value) && value.length <= 11) || value === "") {
            setPhone(value)
        }
    }

    console.log("userData", userData)
    const disabledFieldsIfUserLogged = userEmail && userEmail.length > 0
    const renderFirmaBlock = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                Denumire*:<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={"Denumire"}
                                disabled={disabledFieldsIfUserLogged}
                                value={companyName}
                                onChange={(e) => { setError(); setCompanyName(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                Register Billing Hra<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={"Register Billing Hra"}
                                disabled={disabledFieldsIfUserLogged}
                                value={codeFiscal}
                                onChange={(e) => { setError(); setCodeFiscal(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                                Register Billing Hralaw
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder="Register Billing Hralaw"
                                disabled={disabledFieldsIfUserLogged}
                                value={tradeRegistry}
                                onChange={(e) => { setError(); setTradeRegistry(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                Telefon<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={"Telefon"}
                                disabled={disabledFieldsIfUserLogged}
                                value={companyPhone}
                                onChange={(e) => { setError(); setCompanyPhone(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                Fax<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={"Fax"}
                                value={companyFax}
                                disabled={disabledFieldsIfUserLogged}
                                onChange={(e) => {
                                    setError();
                                    setCompanyFax(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>


                {/* <div className="row">
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <label className="info-title">
                                Judet<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Judet')}
                                value={companyJudet}
                                onChange={(e) => { setError(); setCompanyJudet(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                Oras<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Oras')}
                                value={companyCity}
                                onChange={(e) => { setError(); setCompanyCity(e.target.value) }}
                            />
                        </div>
                    </div>
                </div> */}
            </>
        )
    }

    const renderPFBlock = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <label className="info-title">
                            Modalitate de plata*:
                        </label>
                      
                        {<select defaultValue={paymentMethod} className="form-control unicase-form-control" onChange={(e) => { setError(); setPaymentMethod(e.target.value) }}>
                            <option value="empty" >{t('Paymet_method')}*</option>
                            <option value="sepa" selected={paymentMethod === "sepa"}>SEPA</option>
                            <option value="invoice" selected={paymentMethod === "invoice"}>Invoice</option>
                            <option value="paypal" selected={paymentMethod === "paypal"}>PayPal</option>
                        </select>}
                    </div>

                    <div className="col-md-6">
                        <label className="info-title">
                            Modalitate de livrare*:
                        </label>
                        <select
                            onChange={(e) => { setError(); setDeliveryMethod(e.target.value) }}
                            className="form-control unicase-form-control selectpicker"
                        >
                            <option value="curier-rapid">Curier rapid</option>
                            <option value="ridicare-sediu-comfortmat">Ridicare sediu Comfortmat</option>
                        </select>
                    </div>

                </div>

                <div className="form-group">
                    <label className="info-title">
                        Notes<span>*</span>
                    </label>
                    <textarea
                        className="form-control unicase-form-control"
                        onChange={(e) => { setError(); setNote(e.target.value) }}
                        value={note} />
                </div>
            </>
        )
    }



    const renderContent = () => {
        return (
            <div className="contact-form">
                <div className="col-md-12 contact-title">
                    <h4>{t('Order_form')}</h4>
                </div>
                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                {t('Your_name')}<span>*</span>
                            </label>
                            <input type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Your_name')}
                                disabled={disabledFieldsIfUserLogged}
                                value={name}
                                onChange={(e) => { setNameCheck(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                {t('Phone')}<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Phone')}
                                disabled={disabledFieldsIfUserLogged}
                                value={phone}
                                onChange={(e) => { setError(); setPhoneCheck(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                                Email
                            </label>
                            <input
                                type="email"
                                className="form-control unicase-form-control text-input"
                                placeholder="Email"
                                value={email}
                                disabled={disabledFieldsIfUserLogged}
                                onChange={(e) => { setError(); setEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-4 ">
                        <div className="form-group">
                            <label className="info-title">
                                Country<span>*</span>
                            </label>
                            <input type="email"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('Country')}
                                disabled={disabledFieldsIfUserLogged}
                                value={judet}
                                onChange={(e) => { setError(); setJudet(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label
                                className="info-title" >
                                Oras<span>*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control unicase-form-control text-input"
                                placeholder={t('City')}
                                disabled={disabledFieldsIfUserLogged}
                                value={city}
                                onChange={(e) => { setError(); setCity(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label className="info-title">
                                Cod poştal
                            </label>
                            <input
                                type="text"
                                disabled={disabledFieldsIfUserLogged}
                                className="form-control unicase-form-control text-input"
                                placeholder="Code postal"
                                value={codePostal}
                                onChange={(e) => { setError(); setCodePostal(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>



                {/* <div className="col-md-4">

                    <p className="form-row form-row-first mx-number woocommerce-validated">
                        <span className="minus" onClick={() => { setError(); changeCount("dec") }}>-</span>
                        <label className="">{t('Cutlery')}</label>
                        <span className="woocommerce-input-wrapper">
                            <input type="number" className="input-text " name="shipping_cutlery" placeholder="" value={count} min="0" max="10" />
                        </span>
                        <span className="plus" onClick={() => { setError(); changeCount() }}>+</span>
                    </p>

                </div> */}




                {/* <div className="form-group">
                    <label className="info-title">
                        {t('Adres')}<span>*</span>
                    </label>
                    <textarea className="form-control unicase-form-control"
                        onChange={(e) => { setError(); setAdress(e.target.value) }} value={adress} />
                </div> */}

                <div className="delivery-container" style={{ marginBottom: 10 }}>
                    <div className="fields-block">
                        <h4>{t('Facture_type')}</h4>
                        <div className="shipping">
                            <div>
                                <ul id="shipping_method" className="factura-type" style={{ display: "flex" }}>
                                    <li>
                                        <input
                                            type="radio"
                                            name="facturaType"
                                            disabled={loginData.email.length > 0}
                                            checked={facturaType === "pf"}
                                            onChange={() => setFacturaType("pf")}
                                            id="pf"
                                        />
                                        <label htmlFor="pf">Persoană fizică</label></li>
                                    <li>
                                        <input type="radio" name="facturaType"
                                            checked={facturaType === "firma"}
                                            disabled={loginData.email.length > 0}
                                            onChange={() => setFacturaType("firma")}
                                            id="firma" />
                                        <label htmlFor="firma">Firma</label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {facturaType === "firma" && renderFirmaBlock()}
                {renderPFBlock()}

                {error && <div className="col-md-12 send-order-error-container">{error}</div>}
                <div className="col-md-12 outer-bottom-small m-t-20">
                    <button type="submit"
                        className="btn-upper btn btn-primary checkout-page-button"
                        disabled={!note || !phone || !name || isDisabled || !isValidEmail(email)}
                        onClick={() => setOrder()}
                    >
                        {t('Set_order')}
                    </button>
                </div>
            </div>
        )
    }

    const content = isLoading ? <Spinner /> : renderContent()

    return (
        <div className="login-form">
            {content}
        </div>
    )
}

const mapStateToProps = (state) => ({
    cartData: state.AddToCartReducer.cartData,
    loginData: state.SetLoginReducer.loginData,
});

export default withRouter(connect(mapStateToProps, {
    getCartData,
    getCartInfoFirstLoad
})(DeliveryForm));
