
import React from 'react';

export const BrushIcon = ({ className, onClick }) => {
    return (
        <svg {...{ className, onClick }} width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="34" height="34" rx="17" fill="#E95A0E" fill-opacity="0.3" />
            <path d="M26.81 8.94C25.27 12.78 21.41 18 18.18 20.59L16.21 22.17C15.96 22.35 15.71 22.51 15.43 22.62C15.43 22.44 15.42 22.24 15.39 22.05C15.28 21.21 14.9 20.43 14.23 19.76C13.55 19.08 12.72 18.68 11.87 18.57C11.67 18.56 11.47 18.54 11.27 18.56C11.38 18.25 11.55 17.96 11.76 17.72L13.32 15.75C15.9 12.52 21.14 8.64 24.97 7.11C25.56 6.89 26.13 7.05 26.49 7.42C26.87 7.79 27.05 8.36 26.81 8.94Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M15.43 22.62C15.43 23.72 15.01 24.77 14.22 25.57C13.61 26.18 12.78 26.6 11.79 26.73L9.33003 27C7.99003 27.15 6.84003 26.01 7.00003 24.65L7.27003 22.19C7.51003 20 9.34003 18.6 11.28 18.56C11.48 18.55 11.69 18.56 11.88 18.57C12.73 18.68 13.56 19.07 14.24 19.76C14.91 20.43 15.29 21.21 15.4 22.05C15.41 22.24 15.43 22.43 15.43 22.62Z" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19.24 19.47C19.24 16.86 17.12 14.74 14.51 14.74" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};