import React from 'react';
import {
    showCurrencyPrice,
} from "../../../../helpers/constants/functions";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";

import './styles.scss'

function CartTotal(props) {
    const { cartData: { totalItems, totalSumm }, currency, t, euro, usd } = props


    return (
        <div className="col-md-4 col-sm-12 cart-shopping-total">

            <div className="cart-sub-total">
                <label>{t('Total')}</label>
                <span className="inner-left-md">{showCurrencyPrice(totalSumm, currency, euro, usd)} {currency} <b>x({totalItems})</b></span>
            </div>

            <div className="cart-checkout-btn pull-right">
                <Link to={`/checkout`} className="btn btn-primary checkout-btn">
                    {t('Proceed_to_checkout')}
                </Link>
            </div>
        </div>
    )
}
// export default CartTotal


const mapStateToProps = (state) => ({
    euro: state.GlobalSettingsReducer.euro,
    usd: state.GlobalSettingsReducer.usd,
});

export default withRouter(compose(connect(mapStateToProps, {
}), withNamespaces())(CartTotal));
