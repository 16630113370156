import React, { useEffect, useState } from "react";
import Header from "../../header";
import Footer from "../../footer";
import { Link } from "react-router-dom"
import {
  GET_ALL_CARS,
  GET_ALL_CARS_BODY,
  GET_ALL_CARS_WORK_ZONE,
  GET_VARIANTS_DATA,
  GET_AVAILABLE_CAR_BODY
} from "../../../helpers/constants";
import axiosFetch from "../../../helpers/axios";
import Spinner from "../../../helpers/spinner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import classNames from "classnames"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import emptyCard from "../../../images/empty-cart.png"
import arch from "../../../images/car-zones/arch.svg"
import capot from "../../../images/car-zones/capot.svg"
import crisha from "../../../images/car-zones/crisha.svg"
import door from "../../../images/car-zones/door.svg"
import floor from "../../../images/car-zones/floor.svg"
import per_panel from "../../../images/car-zones/per_panel.svg"
import trunk from "../../../images/car-zones/trunk.svg"
import trunk_lid from "../../../images/car-zones/trunk_lid.svg"
import { withNamespaces } from "react-i18next";
import { compose } from "redux";

import "./styles.scss";

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Calculator({ globalSearchResult, t }) {

  const [isLoading, setIsLoading] = useState(false);
  const [carList, setCarlist] = useState([]);
  const [сarBody, setСarBody] = useState([]);
  const [сarWorkZone, setСarWorkZone] = useState([]);
  const [activeTab, setActiveTab] = useState("optimum");
  const [showVariantTable, setShowVariantTable] = useState(false);
  const [isGettingAvailableCarsBody, setIsGettingAvailableCarsBody] = useState(false);
  const [availableCarsBodyList, setAvailableCarsBodyList] = useState([]);
  const [wasFetched, setWasFetched] = useState(false);
  // const [isLoadingVariantTable, setIsLoadingVariantTable] = useState(false);
  const [variantsData, setVariantsData] = useState([]);
  const [isCalcing, setIsCalcing] = useState(false);
  // const [totalSumm, setTotalSumm] = useState(0);

  // useEffect(() => {

  //   const tableData = variantsData.find(table => table.name === activeTab) || []
  //   const arrTest = []
  //   сarWorkZone.forEach((zone) => {
  //    const selZone = calcData.selectedCarWorkZone.findIndex(selZone => Number(selZone) === Number(zone.id)) > -1
  //    selZone && arrTest.push(...tableData.items.filter(i => i.zone_id === zone.id ))
  //   })
  //   let summ = 0;

  //   globalSearchResult.forEach(product => summ += Number(product.price))
  //   setTotalSumm(summ)
  //   console.log("summ", summ)
  // } ,[activeTab])

  const zonesImages = {
    1: capot,
    2: trunk_lid,
    3: crisha,
    4: floor,
    5: door,
    6: trunk,
    7: arch,
    8: per_panel,
  }

  const [calcData, setСalcData] = useState({
    selectedCar: "",
    selectedCarModel: "",
    selectedCarBody: "",
    selectedCarWorkZone: [],
  });

  useEffect(() => {
    const getCarsList = () => {
      setIsLoading(true);
      const carListResponse = axiosFetch(GET_ALL_CARS);
      Promise.resolve(carListResponse).then((arrList) => {
        setCarlist(arrList);
        setIsLoading(false);
      });
    };

    const getCarsBodyList = () => {
      setIsLoading(true);
      const res = axiosFetch(GET_ALL_CARS_BODY);
      Promise.resolve(res).then((arrList) => {
        setСarBody(arrList);
      });
    };

    const getCarsWorkZoneList = () => {
      setIsLoading(true);
      const res = axiosFetch(GET_ALL_CARS_WORK_ZONE);
      Promise.resolve(res).then((arrList) => {
        setСarWorkZone(arrList);
      });
    };

    getCarsList();
    getCarsBodyList();
    getCarsWorkZoneList();
  }, []);

  //

  const renderSelectCars = () => {
    const content = carList.map((car, index) => {
      return (
        <MenuItem value={car.id} key={index}>{car.name}</MenuItem>
      )
    })

    return content
  }

  const renderSelectCarsBody = () => {

    const filteredArr = сarBody.filter(car => availableCarsBodyList.findIndex(availableCarBody => car.id === availableCarBody) > -1);
    const content = filteredArr.map((car, index) => {
      return (
        <MenuItem value={car.id} key={index}>{car.name}</MenuItem>
      )
    })

    return content
  }

  const checkZone = (id) => {


    const arr = [];
    const findIndex = calcData.selectedCarWorkZone.findIndex((x) => x === id);

    if (findIndex === -1) {
      arr.push(...calcData.selectedCarWorkZone, id);
    } else {
      const newArr = [
        ...calcData.selectedCarWorkZone.slice(0, findIndex),
        ...calcData.selectedCarWorkZone.slice(findIndex + 1),
      ];
      arr.push(...newArr);
    }

    // setSelectedCarWorkZone(arr)

    setСalcData({
      ...calcData,
      selectedCarWorkZone: [...arr]
    })
  }


  const renderSelectCarsWorkZones = () => {
    const content = сarWorkZone.map((zone, index) => {
      return (
        <div
          key={index}
          className="check-zone"
          onClick={(e) => checkZone(zone.id)}>
          {zone.name}

          {calcData.selectedCarWorkZone.findIndex((x) => x === zone.id) >= 0 ?
            <div>
              <CheckCircleIcon />
            </div> :
            <div>
              <RadioButtonUncheckedIcon />
            </div>}
          {/* <Checkbox
            {...label}
            checked={selectedCarWorkZone.findIndex(z => z === zone.id) > -1}
            id={`zone-${zone.id}`}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          /> */}
        </div>
      )
    })

    return content
  }

  const renderSelectCarsInfo = () => {

    return (
      <div className="tehnology">
        <div >
          Tehnologie de prelucrare
          <Checkbox
            {...label}
            checked={true}
            // onChange={(e) => onSelectTehnology(e.target.value)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        </div>

        <div >
          Dimensiunea foii
          <Checkbox
            {...label}
            checked={true}
            // onChange={(e) => checkZone(zone.id, e.target.checked)}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        </div>
      </div>

    )

  }



  const renderSelectCarsModels = () => {
    // console.log("carList", carList)
    const carsModelsContent = carList.find(car => car.id === calcData.selectedCar);

    const content = carsModelsContent.models.map((carModel, index) => {
      return (
        <MenuItem value={carModel.id} key={index}>{carModel.name}</MenuItem>
      )
    })

    return content;
  }
  // console.log("calcData", calcData)
  const calcTableData = () => {
    setIsCalcing(true)

    const formData = new FormData()
    formData.append("car_id", calcData.selectedCar);
    formData.append("car_model", calcData.selectedCarModel);
    formData.append("car_body", calcData.selectedCarBody)

    const res = axiosFetch(GET_VARIANTS_DATA, formData);
    Promise.resolve(res).then((arrList) => {
      setVariantsData(arrList);
      setIsCalcing(false)
      setShowVariantTable(true)
    });

  }

  const fetchAndCheckCarBody = (carModel) => {

    setIsGettingAvailableCarsBody(true)
    setShowVariantTable(false)
    setСalcData({
      ...calcData,
      selectedCarModel: carModel
    })

    const formData = new FormData()
    formData.append("car_id", calcData.selectedCar);
    formData.append("car_model", carModel);

    axiosFetch(GET_AVAILABLE_CAR_BODY, formData).then(res => {
      // console.log("ressssssssssss ", res, carModel)
      setTimeout(() => {
        setWasFetched(true)
        if (res.length === 1) {
          setСalcData({
            ...calcData,
            selectedCarModel: carModel,
            selectedCarBody: res[0]
          })
        }
        setAvailableCarsBodyList(res)
        setIsGettingAvailableCarsBody(false)
      }, 1000);

    });

  }

  const onChange = (state, value) => {
    if (state === "selectedCarModel") {
      fetchAndCheckCarBody(value)
    } else {
      setСalcData({
        ...calcData,
        [state]: value
      })
    }

  }

  const renderCalcContent = () => {

    return (
      <div>
        <h4 className="page-title">Comfortmat Calculator</h4>
        <div className="calc-options">
          <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selectați marca de mașină</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={calcData.selectedCar}
                label="Selectați marca de mașină"
                // onChange={(e) => onSelectCar(e.target.value)}
                onChange={(e) => onChange("selectedCar", e.target.value)}
              >
                {renderSelectCars()}
              </Select>
            </FormControl>
          </Box>


          {calcData.selectedCar > 0 && <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selectați model</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={calcData.selectedCarModel}
                label="Selectați model"
                onChange={(e) => onChange("selectedCarModel", e.target.value)}
              >
                {renderSelectCarsModels()}
              </Select>
            </FormControl>
          </Box>}



        </div>
        <div style={{ display: "flex", justifyContent: "center", marginTop: 20 }}>
          {isGettingAvailableCarsBody && <CircularProgress size={32} />}
        </div>

        {availableCarsBodyList.length > 0 ? <div style={{ marginTop: 20 }}>

          {calcData.selectedCarModel > 0 && !isGettingAvailableCarsBody && <Box>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Selectați caroseria mașinii</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={calcData.selectedCarBody}
                label="Selectați caroseria mașinii"
                disabled={availableCarsBodyList.length === 1}
                onChange={(e) => onChange("selectedCarBody", e.target.value)}
              >
                {renderSelectCarsBody()}
              </Select>
            </FormControl>
          </Box>}

          {calcData.selectedCarBody > 0 && <div className="zones-container">
            {renderSelectCarsWorkZones()}
          </div>}

          <div className="zones-container">
            {renderSelectCarsInfo()}
          </div>

          <div className="zones-container">
            <Button
              variant="contained"
              className={classNames({
                'calc-btn': true,
                'calc-btn-disabled': calcData.selectedCarWorkZone.length === 0 || isCalcing,
              })}
              onClick={() => calcTableData()}
              disabled={calcData.selectedCarWorkZone.length === 0 || isCalcing}
            >
              Calcula

              {isCalcing && <CircularProgress />}

            </Button>
          </div>


        </div> :
          availableCarsBodyList.length === 0 && wasFetched && !isGettingAvailableCarsBody &&
          <div style={{ color: "#af2121", textAlign: "center" }}>
            Din păcate, nu avem un calcul gata făcut pentru această marcă de mașină și modelul pe care l-ați ales.
          </div>}
      </div>

    );
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderVariantZoneProducts = (list) => {


    const content = list.map(item => {
      const product = globalSearchResult.find(product => product.id === item.product_id)
      return (
        <div className="product-count-list">
          <Link to={`/details/${product.id}`}>
            {product.nameRo}
          </Link>
        </div>
      )
    })
    return content
  }

  const renderVariantZoneProductsCounts = (list) => {
    const content = list.map(item => {
      return (
        <div className="product-count-list">{item.count} buc.</div>
      )
    })
    return content
  }

  const renderTableLines = (items = []) => {

    const content = сarWorkZone.map((zone, index) => {
      const showZone = items.length > 0 && items.findIndex(item => item.zone_id === zone.id) > -1
      const checkIfZoneChecked = calcData.selectedCarWorkZone.findIndex(selZone => Number(selZone) === Number(zone.id)) > -1
      const onlyProductsByZone = items.filter(i => i.zone_id === zone.id)

      return (
        <div key={index}
          className={classNames({
            'table-line': true,
            'show-line': showZone && checkIfZoneChecked,
          })}
        >
          <div>
            <img src={zonesImages[zone.id]} height="60" width="160" alt="" />
          </div>
          <div className="car-zone">{zone.name}</div>

          <div className="product-list">
            {renderVariantZoneProducts(onlyProductsByZone)}
          </div>
          <div className="product-count">
            {renderVariantZoneProductsCounts(onlyProductsByZone)}
          </div>
        </div>
      )
    })

    return content
  }

  const renderEmptyCartContainer = () => {
    return (
      <div className="empty-container-title">
        <img src={emptyCard} alt={t('Cart_empty')} />
      </div>

    )
  }

  const renderTable = (tableType) => {

    const tableData = variantsData.find(table => table.name === tableType) || []
    console.log("tableData.items", tableData.items)
    const content = tableData.items ? renderContentForVariant(tableData.items || []) : renderEmptyCartContainer()
    return content

  }

  const renderContentForVariant = (items) => {
    let summ = 0;
    const testarr = []
    сarWorkZone.forEach((zone) => {
      testarr.push(...items.filter(i => i.zone_id === zone.id && calcData.selectedCarWorkZone.findIndex(z => z === zone.id) > -1))
    })

    globalSearchResult.forEach(product => testarr.findIndex(prod => {
      if (Number(prod.product_id) === Number(product.id)) {
        summ += Number(product.price) * Number(prod.count);
      }
    }))

    return (
      <div className="variant-container">
        {summ === 0 ?
          renderEmptyCartContainer()
          : <>
            <div className="table-header table-line">
              <div>Zona de procesare</div>
              <div>Nume</div>
              <div>Materialele folosite</div>
              <div>Cantitate</div>
            </div>
            {renderTableLines(items || [])}

            <div class="calculation__summ">

              <div class="calculation__summ-all">
                <span class="vaf calculation__summ-total">Total:</span>
                <span class="vaf calculation__summ-value">{summ} RON</span>
              </div>
            </div>
          </>}

      </div>
    )
  }


  const renderCalcTabs = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="STandart" value={"standart"} />
            <Tab label="Optimum" value={"optimum"} />
            <Tab label="Premium" value={"premium"} />
          </Tabs>
        </Box>
        <div
          className={classNames({
            'tab-container': true,
            'show': activeTab === "standart",
          })}
        >
          {activeTab === "standart" && renderTable("standart")}
        </div>
        <div
          className={classNames({
            'tab-container': true,
            'show': activeTab === "optimum",
          })}
        >
          {activeTab === "optimum" && renderTable("optimum")}
        </div>
        <div
          className={classNames({
            'tab-container': true,
            'show': activeTab === "premium",
          })}
        >
          {activeTab === "premium" && renderTable("premium")}
        </div>
      </Box>
    )
  }

  const content = isLoading ? (
    <Spinner />
  ) : carList.length > 0 ? (
    renderCalcContent()
  ) : (
    ""
  );
  // console.log("carList", carList)
  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container calculator">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 homebanner-holder">
              {content}
              {showVariantTable && availableCarsBodyList.length > 0 && renderCalcTabs()}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}


const mapStateToProps = (state) => ({
  lng: state.PrefsReducer.lng,
  globalSearchResult: state.MenuDataReducer.globalSearchResult,
});


export default withRouter(compose(connect(mapStateToProps, {

}), withNamespaces())(Calculator));
