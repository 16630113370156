import React, { useEffect, useState } from 'react';
import { withRouter, Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { compose } from "redux";
import { setGlobalCurrency, changeLanguage } from "../../../actions/MenuDataActionCreators"
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import { resetCartData, getCartData } from "../../../actions/AddToCartActionCreator"
import { withNamespaces } from "react-i18next";
// import langRu from "../../../images/ru.png"
import CookieHelper from "../../../helpers/cookie-helper";
import langEn from "../../../images/en.png"
import langRo from "../../../images/md.png"
//import Button from '../../../helpers/button'
import SearchField from "../header-middle-bar/search-field"
import logo from "../../../images/logo.svg";
import dbshop from "../../../images/dbshop.jpg";
// import phone from "../../../images/phn.svg";
import { AuthIcon } from "../../../helpers/icons/icon-auth"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Input from '../../../helpers/input'
import axiosFetch from "../../../helpers/axios"
import { CHANGE_PASSWORD, REMOVE_ALL_CART_DATA } from '../../../helpers/constants'
import { withSnackbar } from 'notistack';

import './styles.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HeaderTopBar(props) {

  const emptyValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const { setGlobalCurrency, currency, changeLanguage, lng, t, loginData, setLoginAuth, enqueueSnackbar, resetCartData, getCartData } = props

  const [redirect, setRedirect] = useState(false)
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [newData, setNewData] = useState({ ...emptyValues })
  const [error, setError] = useState("")
  // const [isLoading, setIsLoading] = useState(false)

  const { oldPassword, newPassword, confirmPassword } = newData

  useEffect(() => {
    const passwordIsChanged = CookieHelper("get", "passwordIsChanged");
    if (Number(passwordIsChanged) === 0) {
      setShowChangePasswordModal(true)
    }
  }, [])


  const renderLoginInfo = () => {
    const { name } = loginData

    return (
      <span style={{ color: "#fff" }}>
        <Link to="/my-account">
          {name}
        </Link>
        <b className="exit-btn" onClick={() => exit()}> ({t('Exit')}) </b>
      </span>
    )
  }

  const renderAuthLink = () => {
    return (
      <Link to="/auth" className="auth">
        <AuthIcon />{t('Autorization')}
      </Link>
    )
  }

  const exit = () => {

    const token = CookieHelper("get", "token");

    const formData = new FormData()
    formData.append("token", token)

    axiosFetch(REMOVE_ALL_CART_DATA, formData).then(res => {
      if (res === "OK") {
        CookieHelper('remove', 'userEmail');
        CookieHelper('remove', 'userName');
        CookieHelper("remove", "passwordIsChanged");

        setLoginAuth({})
        resetCartData()
        setRedirect(true)

        setTimeout(() => {
          getCartData(token)
        }, 1000);
      }
    })



  }

  const setValue = (param, value) => {
    setError()
    const data = {
      ...newData,
      [param]: value
    }
    setNewData(data)

  }

  const setNewPassword = () => {
    // setIsLoading(true)
    const { email } = loginData

    const formData = new FormData()

    formData.append("oldPassword", oldPassword)
    formData.append("newPassword", newPassword)
    formData.append("confirmPassword", confirmPassword)
    formData.append("email", email)






    axiosFetch(CHANGE_PASSWORD, formData)
      // .then((res) => res.toJS())
      .then((result) => {
        console.log("OK", result)
        if (result !== "OK") {
          setError(t(result))
        } else {
          enqueueSnackbar(t('Password_was_changed'))
          CookieHelper("remove", "passwordIsChanged");
          setShowChangePasswordModal(false)
        }
        // setIsLoading(false)
      })
  }

  const changePassword = () => {
    console.log("changePassword--------", newData)


    if (newPassword !== confirmPassword) {
      setError(t('New_password_do_not_match'))
    } else if (oldPassword.length === 0) {
      setError(t('Please_enter_old_password'))
    } else {
      setNewPassword()
    }
  }

  const remindMeLater = () => {
    CookieHelper("set", "passwordIsChanged", 1);
    setShowChangePasswordModal(false)
    enqueueSnackbar(t('Remid_me_later'))
  }

  const currentLang = lng === "en" ? langEn : langRo


  const onhangePasswordModal = () => {
    const disabled = !oldPassword || !newPassword || !confirmPassword
    return (
      <Dialog
        open={showChangePasswordModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setShowChangePasswordModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("You_already_do_not_change_password")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('Advice_to_change_password')}
          </DialogContentText>

          <div className="form-group">
            <label className="info-title" htmlFor="exampleInputPassword1">{t('Old_password')}<span>*</span></label>
            <Input
              type="password"
              value={oldPassword}
              id="register-oldPassword"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("oldPassword", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="info-title" htmlFor="exampleInputPassword1">{t('New_password')}<span>*</span></label>
            <Input
              type="password"
              value={newPassword}
              id="register-newPassword"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("newPassword", e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="info-title" htmlFor="exampleInputPassword1">{t('Confirm_password')}<span>*</span></label>
            <Input
              type="password"
              value={confirmPassword}
              id="register-confirmPassword"
              className="form-control unicase-form-control text-input"
              onChange={(e) => setValue("confirmPassword", e.target.value)}
            />
          </div>
          {error && <div className="error-container">{error}</div>}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => remindMeLater()}
          >{t('Later')}
          </Button>
          <Button
            variant="contained"
            onClick={() => changePassword()}
            disabled={disabled}
          >{t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <div className="top-bar animate-dropdown">
      {redirect && <Redirect to="/" />}
      {showChangePasswordModal && onhangePasswordModal()}
      <div className="container top-header">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
        <div className="header-top-inner main-header">
          <SearchField {...{ t }} />
          <div className="cnt-account">

            <ul className="list-unstyled">
              {loginData.name &&
                <li>
                  <Link to="/my-account">
                    {/* <AuthIcon /> */}
                    {t('My_account')}
                  </Link>
                </li>
              }

              <li>
                {loginData.name ? renderLoginInfo() : renderAuthLink()}
              </li>
            </ul>

            <div className="header-phone">
              {/* <div className="header-phone-icon">
                <img src={phone} alt="icon" style={{ width: 30 }} />
              </div>

              <div className="header-phone-text">
                <a href={`tel:${contacts.phone}`} className="number"><span className="subnumber">{contacts.phone}</span></a>
              </div> */}
              <div className="disributor">

                <a href="https://dbshop.ro/" target="_blank" rel="noopener norefner noreferrer">
                  <img src={dbshop} alt="" style={{ width: 130 }} />
                </a>
                <br />
                <div style={{ marginLeft: 8 }}>Distribuitor național</div>
                {/* <a href="" className="number" 
                  rel="noopener norefner noreferrer"><span className="subnumber">https://dbshop.ro/</span></a> */}
              </div>


            </div>

            {/* <div className="disributor">
              Distribuitor național: <a href="https://dbshop.ro/" className="number" target="_blank"
                rel="noopener norefner noreferrer"><span className="subnumber">https://dbshop.ro/</span></a>
            </div> */}
          </div>

          <div className="cnt-block">
            <ul className="list-unstyled list-inline">
              {/* <li>
                <Button
                  type="button"
                  className="btn-upper btn btn-primary checkout-page-button"
                  text={showTheme ? 'Показать светлую тему' : 'Показать тёмную тему'}
                 // disabled={!login || !password}
                  onClick={() => themesStyles()}
                />
              </li> */}
              <li className="dropdown dropdown-small">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value">{currency} </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu currency-menu">
                  {/* <li>
                    <span onClick={() => setGlobalCurrency("USD")}>USD</span>
                  </li> */}
                  <li>
                    <span onClick={() => setGlobalCurrency("EURO")}>EURO</span>
                  </li>
                  <li>
                    <span onClick={() => setGlobalCurrency("RON")}>RON</span>
                  </li>
                </ul>
              </li>

              <li className="dropdown dropdown-small languages-container">
                <a
                  href="/"
                  className="dropdown-toggle"
                  data-hover="dropdown"
                  data-toggle="dropdown"
                >
                  <span className="value"><img src={currentLang} alt={currentLang} /> </span>
                  <b className="caret"></b>
                </a>
                <ul className="dropdown-menu languages">
                  <li onClick={() => changeLanguage("en")}>
                    <span><img src={langEn} alt={langEn} /></span>
                  </li>
                  <li onClick={() => changeLanguage("ro")}>
                    <span><img src={langRo} alt={langRo} /></span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  loginData: state.SetLoginReducer.loginData,
  contacts: state.AddToCartReducer.contacts,
});

export default withRouter(compose(connect(mapStateToProps, {
  setGlobalCurrency,
  setLoginAuth,
  changeLanguage,
  resetCartData,
  getCartData
}), withNamespaces())(withSnackbar(HeaderTopBar)));