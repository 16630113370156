import React, { useState, useEffect } from 'react';
import { useParams, withRouter } from "react-router-dom"
import Header from "../../header";
import Footer from "../../footer";
//import HotDeals from "../widgets/hot-deals"
import axiosFetch from "../../../helpers/axios"
import RelatedProducts from "../widgets/related-products"
import { GET_PRODUCT_INFO_BY_ID, APP_TITLE } from "../../../helpers/constants"
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// import Categories from "../widgets/categories"
//import Testimonials from "../widgets/testimonials"
import { CloseRemoveIcon } from "../../../helpers/icons/icon-close-remove"
import { ArrowIcon } from "../../../helpers/icons/icon-arrow"
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import Spinner from "../../../helpers/spinner"
import CookieHelper from "../../../helpers/cookie-helper";
import { addItemToMyCart, getCartData } from "../../../actions/AddToCartActionCreator"
import { setGlobalCategoryId } from "../../../actions/MenuDataActionCreators"
import { connect } from "react-redux";
import { withSnackbar } from 'notistack';
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { showCurrencyPrice } from "../../../helpers/constants/functions"

import './styles.scss'

function ProductDetails(props) {
  const { productId } = useParams()
  const [productInfo, setProductInfo] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [productCount, setProductCount] = useState(1)

  const { t, lng, enqueueSnackbar, addItemToMyCart, getCartData, setGlobalCategoryId, currency, euro, usd } = props
  const { nameRu, nameRo, price, discount, descriptionRu, descriptionRo, images, category, subcategory, available, count } = productInfo
  const finalProductName = lng === "en" ? nameRu : nameRo
  const finalProductDescription = lng === "en" ? descriptionRu : descriptionRo
  const userEmail = CookieHelper("get", "userEmail");

  const finalName = finalProductName ? finalProductName : t('Not_filled')

  document.title = `${APP_TITLE}${finalName}`
  useEffect(() => () => setGlobalCategoryId(null), [setGlobalCategoryId]);


  useEffect(() => {
    const getProductDetails = () => {
      setIsLoading(true)
      const formData = new FormData()
      formData.append("userEmail", userEmail)
      formData.append("productId", productId)

      const productResult = axiosFetch(GET_PRODUCT_INFO_BY_ID, formData)
      promiseResolved(productResult)
    }


    const promiseResolved = (productsResult) => {
      Promise.resolve(productsResult).then((pInfo) => {
        console.log("pInfo[0]", pInfo[0])
        setProductInfo(pInfo[0])
        setGlobalCategoryId(pInfo[0].category)
        setIsLoading(false)
      })
    }

    getProductDetails()
  }, [productId, setGlobalCategoryId, userEmail])



  // const renderCarouselContent = (images) => {
  //   const content = images.map((oneImage, index) => {
  //     const { imageName } = oneImage

  //     return (
  //       <div key={index}>
  //         <img src={imageName} alt="" />
  //       </div>
  //     )
  //   })

  //   return content
  // }
  const imagesContent = () => {
    const test = images.map(({ imageName }) => ({
      src: imageName
    }));

    return test
  }


  const addItemToCart = (id, finalName, price) => {
    setIsLoading(true)
    const token = CookieHelper("get", "token");
    setTimeout(() => {
      enqueueSnackbar(t('Been_added', { finalName }));
      const cartData = {
        id,
        finalName,
        count: productCount,
        price,
        token
      }
      addItemToMyCart(cartData)
      setIsLoading(false)

      setTimeout(() => {
        getCartData(token)
      }, 1000);

    }, 500);
  }

  //sostav - это граммаж
  const isAvailable = Number(available) === 0 ? t('No') : t('Yes')

  // const test = images.map(({imageName}) => ({
  //   src: imageName
  // }));

  const onChnageProductCount = (value) => {

    if (value < 1) {
      setProductCount(1)
      enqueueSnackbar(t('Cannot_be_less'));
    } else {
      setProductCount(value)
    }
  }

  const priceAfterDiscount = price - (price * discount / 100);

  const renderContent = () => {
    return (
      <>
        <div className="col-xs-12 col-sm-6 col-md-5 gallery-holder">

          <div className="product-item-holder size-big single-product-gallery small-gallery">

            {/* <Carousel showArrows={true} emulateTouch={true} showIndicators={false} >
              {images && renderCarouselContent(images)}
            </Carousel> */}
            <Carousel
              images={images ? imagesContent() : []}
              className="carousel"
              playIcon={""}
              pauseIcon={""}
              widgetsHasShadow
              minIcon={<CloseRemoveIcon className="slider-close-icon"/>}
              maxIcon={""}
              shouldMaximizeOnClick
            />
            <div className="product-info">
              <div className="price-container info-container m-t-20">
                <div className="row">


                  <div className="col-sm-12">
                    <div className="price-box">
                      <span className="price">{showCurrencyPrice(priceAfterDiscount, currency, euro, usd)} {currency}</span>
                      {discount > 0 && <span className="price-strike">{showCurrencyPrice(price, currency, euro, usd)} {currency}</span>}
                      {discount > 0 && <span className="percent-discount">-{discount}%</span>}
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
        <div className='col-sm-6 col-md-7 product-info-block'>
          <div className="product-info">
            <h1 className="name">{finalName}</h1>

            <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-4">
                  <div className="stock-box">
                    <span className="label">{t('Product_available')} :</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="stock-box">
                    <span className="value">{isAvailable}</span>
                  </div>
                </div>
              </div>
            </div>

            {count > 0 && <div className="stock-container info-container m-t-10">
              <div className="row">
                <div className="col-sm-4">
                  <div className="stock-box">
                    <span className="label">{t('Qty')}  :</span>
                  </div>
                </div>
                <div className="col-sm-8">
                  <div className="stock-box">
                    <span className="value">{count} {t('Pieces')}.</span>
                  </div>
                </div>
              </div>
            </div>}

            <div className="description-container m-t-20">
              <div
                dangerouslySetInnerHTML={{
                  __html: finalProductDescription
                }} />
            </div>


            <div className="quantity-container info-container">

              <div className="quant-input">


                <div
                  className="arrow plus gradient"
                  onClick={() => onChnageProductCount(productCount + 1)}
                >
                  <span className="ir">
                    <ArrowIcon className="up arrow-svg" />
                  </span>
                </div>
                <input type="number" value={productCount}
                  onChange={(e) => onChnageProductCount(e.target.value)}
                />

                <div className="arrow minus gradient"
                  onClick={() => onChnageProductCount(productCount - 1)}
                >
                  <span className="ir">
                    <ArrowIcon className="down arrow-svg" />
                  </span>
                </div>


              </div>

              <button
                className="btn btn-primary icon"
                data-toggle="dropdown"
                type="button"
                style={{ width: "100%", background: "#e95a0e" }}
                disabled={Number(available) === 0}
                onClick={() => addItemToCart(productId, finalName, priceAfterDiscount)}
              >
                {Number(available) === 0 ? t('Product_unavailable') : t('To_cart')}
              </button>
            </div>

          </div>
        </div>
      </>
    )
  }





  const content = isLoading ? <Spinner /> : renderContent()



  return (
    <div className="cnt-home">
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row">
            {/* <div className="col-xs-12 col-sm-12 col-md-3 sidebar">
              <Categories currentCategory={category} />
              <HotDeals />
            </div> */}
            <div className="col-xs-12 col-sm-12 col-md-12 single-product">
              <div className="detail-block">
                <div className="row  wow fadeInUp">
                  {content}
                </div>
              </div>
              {category && <RelatedProducts subCategoryId={subcategory} category={category} />}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  lng: state.PrefsReducer.lng,
  euro: state.GlobalSettingsReducer.euro,
  usd: state.GlobalSettingsReducer.usd,
});
export default withRouter(compose(connect(mapStateToProps, {
  addItemToMyCart,
  getCartData,
  setGlobalCategoryId
}), withNamespaces())(withSnackbar(ProductDetails)));
