import React, { useState, useEffect, useCallback } from 'react';
import Header from "../../header";
import Footer from "../../footer";
import classNames from 'classnames'
import { BrushIcon } from "../../../helpers/icons/icon-brush"
import { CloseRemoveIcon } from "../../../helpers/icons/icon-close-remove"
import { SaveIcon } from "../../../helpers/icons/icon-save"
import { TickCircleIcon } from "../../../helpers/icons/icon-tick-circle"
import { CallIcon } from "../../..//helpers/icons/icon-call"
import { LocationIcon } from "../../..//helpers/icons/icon-location"
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { setGlobalCurrency, changeLanguage } from "../../../actions/MenuDataActionCreators"
import { setLoginAuth } from "../../../actions/SetLoginAuthActionCreators"
import { withNamespaces } from "react-i18next";
import Input from '../../../helpers/input'
import { countries, registraionFields } from "../../../helpers/constants/data-array"
import Spinner from "../../../helpers/spinner"
import axiosFetch from "../../../helpers/axios"
import { GET_USER_INFO, NO_IMAGE_URL, EDIT_USER_PROFILE, CHANGE_PASSWORD, DELETE_ACCOUNT, GET_USER_ORDERS } from "../../../helpers/constants"
import Button from '../../../helpers/button'
import { scrollTo } from "../../../helpers/constants/functions"
import { withSnackbar } from 'notistack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CookieHelper from "../../../helpers/cookie-helper";
import emptyCard from "../../../images/empty-cart.png"

import './styles.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function MyAccount(props) {
  const {
    // setGlobalCurrency,
    // currency,
    // changeLanguage,
    // lng,
    t,
    // contacts,
    loginData,
    enqueueSnackbar,
    setLoginAuth
  } = props

  const emptyData = {
    phone: "",
    address: "",
    userPicture: "",
    nameSurname: "",
  }

  const emptyAccessValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }

  const [redirect, setRedirect] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);

  const [userOrders, setUserOrders] = useState([]);
  const [ordersIsFetched, setOrdersIsFetched] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);

  const [viewModalData, setViewModalWithData] = useState({});


  const [updateData, setUpdateData] = useState(emptyData)
  const [selectedTab, setSelectedTab] = useState("comenzi")
  const [userData, setUserData] = useState({})
  const [photoPreview, setPhotoPreview] = useState()
  const [accessData, setAccessData] = useState({ ...emptyAccessValues })
  const { oldPassword, newPassword, confirmPassword } = accessData
  const [error, setError] = useState("")

  const { passwordIsChanged, nameSurname, phone, reducere, country, city, street, zipcode } = userData

  useEffect(() => {
    !loginData.name && setRedirect(true)
  }, [loginData])

  const fetchUserOrders = useCallback((id) => {
    const formData = new FormData()
    formData.append("id", id)

    !ordersIsFetched && axiosFetch(GET_USER_ORDERS, formData).then((result) => {
      setUserOrders(result || [])
      setTimeout(() => {
        setIsLoadingOrders(false)
      }, 1000);
      setOrdersIsFetched(true)
    })
  }, [ordersIsFetched])

  const fetchUserData = useCallback(() => {
    const { email } = loginData
    const formData = new FormData()
    formData.append("email", email)
    setIsLoadingOrders(true)
    axiosFetch(GET_USER_INFO, formData).then((result) => {
      setUserData(result[0] || {})
      setUpdateData(result[0] || {})
      setIsLoading(false)

      fetchUserOrders(result[0].id)
    })
  }, [fetchUserOrders, loginData])


  useEffect(() => {
    setIsLoading(true)
    fetchUserData()
  }, [fetchUserData])

  const setNewPassword = () => {
    setIsLoading(true)
    const { email } = loginData

    const formData = new FormData()

    formData.append("oldPassword", oldPassword)
    formData.append("newPassword", newPassword)
    formData.append("confirmPassword", confirmPassword)
    formData.append("email", email)

    axiosFetch(CHANGE_PASSWORD, formData)
      // .then((res) => res.toJS())
      .then((result) => {
        console.log("OK", result)
        if (result !== "OK") {
          setError(result)
        } else {
          enqueueSnackbar(t('Password_was_changed'))
        }
        setIsLoading(false)
      })
  }

  const changePassword = () => {


    if (newPassword !== confirmPassword) {
      setError(t('New_password_do_not_match'))
    } else if (oldPassword.length === 0) {
      setError(t('Please_enter_old_password'))
    } else {
      setNewPassword()
    }
  }

  const setPasswordsValue = (param, value) => {
    setError()
    const data = {
      ...accessData,
      [param]: value
    }
    setAccessData(data)
  }

  const disabled = !oldPassword || !newPassword || !confirmPassword

  const renderAccessTab = () => {
    return (
      <>
        <div className="form-group">
          <label className="info-title" htmlFor="exampleInputPassword1">{t('Old_password')}<span>*</span></label>
          <Input
            type="password"
            autocomplete="off"
            value={oldPassword}
            id="register-oldPassword"
            className="form-control unicase-form-control text-input"
            onChange={(e) => setPasswordsValue("oldPassword", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="info-title" htmlFor="exampleInputPassword1">{t('New_password')}<span>*</span></label>
          <Input
            type="password"
            autocomplete="off"
            value={newPassword}
            id="register-newPassword"
            className="form-control unicase-form-control text-input"
            onChange={(e) => setPasswordsValue("newPassword", e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="info-title" htmlFor="exampleInputPassword1">{t('Confirm_password')}<span>*</span></label>
          <Input
            type="password"
            autocomplete="off"
            value={confirmPassword}
            id="register-confirmPassword"
            className="form-control unicase-form-control text-input"
            onChange={(e) => setPasswordsValue("confirmPassword", e.target.value)}
          />
        </div>
        {error && <div className="error-container">{error}</div>}
        <Button
          type="button"
          className={`btn-upper btn btn-primary submit-btn ${disabled && "disabled-button"}`}
          text={t('Save')}
          disabled={disabled}
          onClick={() => changePassword()}
        />

      </>
    )
  }


  const renderOrders = () => {
    const content = userOrders.map(el => {

      const { orderId, orderItems } = el
      let count = 0
      let summ = 0
      const names = []
      orderItems.forEach(({ productCount, productPrice, productInfo }) => {
        // console.log("productInfoproductInfoproductInfo", productInfo)
        count += Number(productCount)
        summ += Number(productPrice) * Number(productCount)
        productInfo && names.push({ pName: productInfo[0]?.name, pCount: productCount })
      })
      return (
        <div className="orders-product-line short-view" onClick={() => setViewModalWithData(el)}>
          <div className="product-info">

            <div className="order-product-content">
              <div className="order-product-title">Order #{orderId} (x{count})</div>
            </div>
            <div className="order-product-content">
              {names.map(({ pName, pCount }, index) => {
                return (
                  <div key={index}>
                    {pName} <b>(x{pCount})</b>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="cart-info">
            <div className="order-price">{summ} ron</div>
            <div className="add-to-cart-from-order">Uite</div>
          </div>
        </div>
      )
    })

    return content
  }

  const emptyOrderContent = () => {
    return (
      <div className="empty-container-title no-borders">
        <img src={emptyCard} alt={t('Cart_empty')} />
      </div>
    )
  }
  const renderComenziiTab = () => {
    return (
      <div>
        {isLoadingOrders ? <Spinner /> : userOrders.length > 0 ? renderOrders() : emptyOrderContent()}

      </div>
    )
  }

  // const renderModalitateTab = () => {
  //   return (
  //     <div>
  //       modalitate
  //     </div>
  //   )
  // }


  const renderCountryList = () => {
    const options = countries.map(({ label, value }) => {
      return (
        <option value={value} >{label}</option>
      )
    })
    return options
  }

  const setValue = (param, value) => {
    const data = {
      ...userData,
      [param]: value
    }
    setUserData(data)
  }

  const setEditValue = (param, value) => {
    const data = {
      ...updateData,
      [param]: value
    }
    setUpdateData(data)
  }

  const renderRegistrationFields = () => {
    const fields = registraionFields.map((field, index) => {
      const { label, inputId, isEmailValidation, isDisabled = false } = field
      return (
        <div className="form-group" key={index}>
          <label className="info-title" htmlFor="exampleInputPassword1">{t(label)} {isEmailValidation && <span>*</span>}</label>
          <Input
            type="text"
            id={inputId}
            value={updateData[inputId]}
            disabled={isDisabled}
            placeholder={t(label)}
            className={`form-control unicase-form-control text-input`}
            onChange={(e) => setEditValue(inputId, e.target.value)}
          />
        </div>
      )
    })

    return fields
  }

  // const editMyProfile = () => {
  //   console.log("edit my profile")
  // }

  const renderSettingsTab = () => {
    return (
      <div className="settings-tab">
        <div className="panel--body is--wide">

          {renderRegistrationFields()}

          <div className="form-group">
            <label className="info-title" htmlFor="exampleInputPassword1">{t('Select_country')} </label>
            <select defaultValue={updateData.country} className="form-control unicase-form-control" onChange={(e) => setEditValue("country", e.target.value)}>
              <option value="no_country">{t("Select_country")}</option>
              {renderCountryList()}
            </select>
          </div>

          <div className="form-group">
            <label className="info-title" htmlFor="exampleInputPassword1">{t('Paymet_method')} <span>*</span></label>
            <select defaultValue={updateData.paymentMethod} className="form-control unicase-form-control" onChange={(e) => setEditValue("paymentMethod", e.target.value)}>
              <option value="empty" >{t('Paymet_method')}*</option>
              <option value="sepa">SEPA</option>
              <option value="invoice">Invoice</option>
              <option value="paypal">PayPal</option>
            </select>
          </div>

          <Button
            type="button"
            className="btn-upper btn btn-primary submit-btn"
            text={t('Edit')}
            // disabled={!secondPassword || !password || !name || !email}
            onClick={() => saveProfile()}
          />
        </div>
      </div>
    )
  }

  const renderSelectedTabContent = () => {
    switch (selectedTab) {
      case "comenzi":
        return renderComenziiTab();
      case "access":
        return renderAccessTab();
      default:
        return renderSettingsTab()
    }
  }

  const saveProfile = (leftSide = false) => {
    setIsLoading(true);
    const { userPicture } = updateData

    const formData = new FormData()
    formData.append("leftSide", leftSide)
    formData.append("userId", userData.id)

    if (leftSide) {
      formData.append("userPicture", userPicture)

      formData.append("nameSurname", userData.nameSurname)
      formData.append("phone", userData.phone)
    } else {
      formData.append("companyName", updateData.companyName)
      formData.append("nameSurname", updateData.nameSurname)
      formData.append("street", updateData.street)
      formData.append("zipcode", updateData.zipcode)
      formData.append("city", updateData.city)
      formData.append("phone", updateData.phone)
      formData.append("fax", updateData.fax)
      formData.append("mobile", updateData.mobile)
      formData.append("website", updateData.website)
      formData.append("registerBillingHra", updateData.registerBillingHra)
      formData.append("registerBillingHralaw", updateData.registerBillingHralaw)
      formData.append("taxNo", updateData.taxNo)
      formData.append("vatNo", updateData.vatNo)
      formData.append("country", updateData.country)
      formData.append("paymentMethod", updateData.paymentMethod)
    }


    axiosFetch(EDIT_USER_PROFILE, formData).then(() => {
      resetUpdatedData()
      scrollTo("myCabContent")
      setTimeout(() => {
        setIsLoading(false)
        fetchUserData()
      }, 500);
    })


  }

  const resetUpdatedData = () => {
    setIsEditMode(false)
    setUpdateData(emptyData)
    setPhotoPreview(null)
  }

  const showEditStateFrom = () => {
    setIsEditMode(true)
  }

  const renderActionIcons = () => {
    return (
      <div>
        {!isEditMode ? (
          <BrushIcon onClick={() => showEditStateFrom()} />
        ) : (
          <div className="edit-profile">
            {Object.keys(updateData).length > 0 && <SaveIcon onClick={() => saveProfile(true)} />}
            <CloseRemoveIcon onClick={() => resetUpdatedData()} />
          </div>
        )}

      </div>
    )
  }

  const handleChangePhoto = (file) => {
    const data = {
      ...updateData,
      userPicture: file
    }
    setUpdateData(data)
    setPhotoPreview(URL.createObjectURL(file))
  }

  const finishImage = userData?.userPicture?.length === 0 ? NO_IMAGE_URL : userData.userPicture

  const imageSrc = photoPreview ? photoPreview : finishImage;

  const onDeleteAccount = () => {
    setIsLoading(true);

    const formData = new FormData()
    formData.append("userId", userData.id)



    axiosFetch(DELETE_ACCOUNT, formData).then(() => {
      CookieHelper('remove', 'userEmail');
      CookieHelper('remove', 'userName');
      CookieHelper("remove", "passwordIsChanged");
      const data = {}
      setLoginAuth(data)
      setRedirect(true)
      enqueueSnackbar(t('Account_was_deleted_success'))

    })

    showDeleteModal(false)
  }

  const onPrintOrder = () => {
    console.log("print")
  }

  const renderOrderItems = (items) => {
    const content = items.map(item => {
      const { productInfo, productCount, productPrice } = item

      return (

        <div className="orders-product-line">
          <div className="product-info">
            <div className="order-product-image" style={{ backgroundImage: `url(${productInfo[0].image})` }}></div>
            <div className="order-product-content">
              <div className="order-product-title">{productInfo[0].name} </div>
              <div className="cart-info">
                <div className="order-price">{productPrice * productCount} ron</div>
                <div className="add-to-cart-from-order">{productPrice} ron / (x{productCount})</div>
              </div>
              <div className="order-product-description"
                dangerouslySetInnerHTML={{
                  __html: productInfo[0].description
                }} />

              <div className="order-product-disponibil">
                disponibil <span className="green">da***</span>
              </div>
            </div>
          </div>

        </div>
      )
    })

    return content

  }
  const renderViewModalData = () => {
    return (
      <Dialog
        open={viewModalData.orderId}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setViewModalWithData({})}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("Your_order")} #{viewModalData.orderId}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {renderOrderItems(viewModalData.orderItems)}
          </DialogContentText>

        </DialogContent>
        <DialogActions>

          <Button
            type="button"
            className={`btn-upper btn btn-primary submit-btn`}
            text={t('Cancel')}
            onClick={() => setViewModalWithData({})}
          />

          <Button
            type="button"
            className={`btn-upper btn btn-secondary submit-btn`}
            text={t('Print')}
            onClick={() => onPrintOrder()}
          />
        </DialogActions>
      </Dialog>
    )
  }


  const renderDeleteModal = () => {
    return (
      <Dialog
        open={deleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => showDeleteModal(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{t("Deleting_account")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('Are_you_sure_to_delete_acc')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button
            type="button"
            className={`btn-upper btn btn-primary submit-btn`}
            text={t('Cancel')}
            onClick={() => showDeleteModal(false)}
          />

          <Button
            type="button"
            className={`btn-upper btn btn-secondary submit-btn`}
            text={t('Delete')}
            onClick={() => onDeleteAccount()}
          />
        </DialogActions>
      </Dialog>
    )
  }
  return (
    <div className="cnt-home" >
      {redirect && <Redirect to="/" />}
      {deleteModal && renderDeleteModal()}
      {viewModalData.orderId && renderViewModalData()}
      <Header />
      <div className="body-content outer-top-xs">
        <div className="container">
          <div className="row  user-container">
            <div className="col-xs-12 col-sm-12 col-md-4">
              <div className="user-left-side">


                {isLoading ? <Spinner /> : <div className="user-data-content">
                  <div className="delete-acc" id="myCabContent" onClick={() => showDeleteModal(true)}>{t('Delete_account')}</div>
                  <div
                    className={classNames({
                      'user-photo': true,
                      'no-edit-mode': !isEditMode,
                    })}
                    style={{
                      backgroundImage: `url(${imageSrc})`,
                    }}
                  >
                    {renderActionIcons()}

                    {isEditMode && <Input
                      colMd={""}
                      type={"file"}
                      placeholder={"Фотография игрока"}
                      name={"avatar"}
                      onChange={(e) => handleChangePhoto(e.target.files[0])}
                    />}
                  </div>

                  <div className="user-info-container">
                    <div className="user-line-info">
                      <div className="user-ico">
                        <TickCircleIcon />
                      </div>
                      <div className="user-text">
                        {/* {nameSurname} */}
                        {!isEditMode ? nameSurname : (
                          <Input
                            value={userData.nameSurname}
                            className={"form-control custom-input"}
                            type={"text"}
                            placeholder={t('Name_surname')}
                            onChange={(e) => setValue("nameSurname", e.target.value)}
                          />
                        )}
                        {passwordIsChanged && <div className="verified">verified</div>}
                      </div>
                    </div>

                    <div className="user-line-info no-bold m-t-40">
                      <div className="user-ico">
                        <CallIcon />
                      </div>
                      <div className="user-text">
                        {!isEditMode ? phone : (
                          <Input
                            value={userData.phone}
                            className={"form-control custom-input"}
                            type={"text"}
                            placeholder={t('Phone')}
                            onChange={(e) => setValue("phone", e.target.value)}
                          />
                        )}
                      </div>
                    </div>

                    <div className="user-line-info no-bold">
                      <div className="user-ico">
                        <LocationIcon />
                      </div>
                      <div className="user-text">
                        {country === "" &&
                          city === "" &&
                          street === "" &&
                          zipcode === "" ? t('No_data') :
                          <>
                            {country !== "" && `${country}, `}
                            {city !== "" && `${city} ,`}
                            {street !== "" && `${street} ,`}
                            {zipcode !== "" && `${zipcode}`}
                          </>
                        }

                      </div>
                    </div>

                    <div className="user-line-info no-bold reducere">
                      <div className="user-ico">
                      </div>
                      <div className="user-text">
                        Account: <b>-{reducere}%</b>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-8 ">
              <div className="user-right-side">
                <div className="tab-container">
                  <div className="tabs-items">
                    <div className={classNames({
                      'tab-item': true,
                      'tab-active': selectedTab === "comenzi",
                    })}
                      onClick={() => setSelectedTab("comenzi")}>
                      comenzi
                    </div>

                    <div className={classNames({
                      'tab-item': true,
                      'tab-active': selectedTab === "setari",
                    })}
                      onClick={() => setSelectedTab("setari")}>
                      Profil
                    </div>

                    <div className={classNames({
                      'tab-item': true,
                      'tab-active': selectedTab === "access",
                    })}
                      onClick={() => setSelectedTab("access")}>
                      access
                    </div>
                  </div>
                  <div className={classNames({
                    'tabs-content': true,
                    'is-fetching': isLoadingOrders,
                  })}
                  >
                    {renderSelectedTabContent()}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currency: state.MenuDataReducer.currency,
  menuData: state.MenuDataReducer,
  lng: state.PrefsReducer.lng,
  loginData: state.SetLoginReducer.loginData,
  contacts: state.AddToCartReducer.contacts,
});

export default withRouter(compose(connect(mapStateToProps, {
  setGlobalCurrency,
  setLoginAuth,
  changeLanguage
}), withNamespaces())(withSnackbar(MyAccount)));
